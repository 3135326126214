import React, { useState, useEffect } from 'react';
import { MapView } from './OCVSexOffenderMapComponents/MapView';
import { DetailView } from './OCVSexOffenderMapComponents/DetailView';
import { MapMembers } from './OCVSexOffenderMapComponents/MapMembers';

const axios = require('axios');

// React-Google-Maps Documentation
// https://tomchentw.github.io/react-google-maps/#documentation

export default function OCVSexOffendersMap({ link, anchorID, config }) {
  const [locations, setLocations] = useState(null);
  const [currentMarker, setCurrentMarker] = useState(null);
  const [center, setCenter] = useState(null);
  const [zoom, setZoom] = useState(null);
  const [searchName, setSearchName] = useState('');
  const [searchAddress, setSearchAddress] = useState('');
  const [userLocation, setUserLocation] = useState('');
  const [isActive, setIsActive] = useState({
    active: 'name',
  });

  // gets the data of the marker or offender that is clicked
  // uses that data to update the map and isolate that offender's marker
  const handleClick = (member) => {
    setCurrentMarker(member);
    setCenter({ lat: member?.latitude, lng: member?.longitude });
    setZoom(16);
  };

  // resets values altered by the handleClick
  const handleBack = () => {
    setCurrentMarker(null);
    setCenter(null);
    setZoom(config?.zoom);
  };

  // filters the list or map of offenders depending on input
  // sorts alphabetically for name inputs
  // sorts by distance for address inputs
  function filterLocation(locations, searchName = '', searchAddress = '') {
    let filteredLocations = locations?.data?.flat;

    if (searchName.length > 1) {
      filteredLocations = filteredLocations.filter((location) =>
        location.title.toLowerCase().includes(searchName)
      );

      filteredLocations.sort((a, b) => a.title - b.title);
    }
    if (searchAddress.length > 1) {
      filteredLocations = filteredLocations.filter((location) =>
        location.address.toLowerCase().includes(searchAddress)
      );

      filteredLocations.sort((a, b) => a.distance - b.distance);
    }

    for (let i = 0; i < filteredLocations.length; i++) {
      filteredLocations[i]['distance'] = haversine_distance(
        userLocation,
        filteredLocations[i]
      );
    }

    return filteredLocations;
  }

  // uses geolocation to get the user's current address(if they allow it)
  function getUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) =>
        setUserLocation(position.coords)
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }

  // calculates the straight line distance between two sets of coordinates
  // parameters should be objects that contain latitude and longitude properties
  function haversine_distance(mk1, mk2) {
    var R = 3958.8; // Radius of the Earth in miles
    var rlat1 = mk1.latitude * (Math.PI / 180); // Convert degrees to radians
    var rlat2 = mk2.latitude * (Math.PI / 180); // Convert degrees to radians
    var difflat = rlat2 - rlat1; // Radian difference (latitudes)
    var difflon = (mk2.longitude - mk1.longitude) * (Math.PI / 180); // Radian difference (longitudes)

    var d =
      2 *
      R *
      Math.asin(
        Math.sqrt(
          Math.sin(difflat / 2) * Math.sin(difflat / 2) +
            Math.cos(rlat1) *
              Math.cos(rlat2) *
              Math.sin(difflon / 2) *
              Math.sin(difflon / 2)
        )
      );
    return d.toFixed(2);
  }

  // gets the locations from the json through an api call
  useEffect(() => {
    async function fetchLocations() {
      try {
        const response = await axios.get(link);
        setLocations(response.data);
      } catch (err) {
        console.log(err);
      }
    }

    fetchLocations();
  }, [link]);

  // some functions that only need to be called on the component initial render
  useEffect(() => {
    getUserLocation();
    setZoom(config?.zoom);
  }, [config.zoom]);

  return (
    <div
      className={
        'flex flex-col md:flex-row p-2 ' + config?.additionalTailwindStyles
      }
      id={anchorID}
      style={{ flexGrow: 2 }}
    >
      <MapView
        center={center}
        zoom={zoom}
        clusterSize={config?.clusterSize}
        handleClick={handleClick}
        filterLocation={filterLocation}
        locations={locations}
        searchName={searchName}
        searchAddress={searchAddress}
        className="flex-1 w-full p-3"
        isMarkerShown
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyAEOZZNm6jFVe3j3ISl_Ha9BeODMYrdqaY`}
        loadingElement={<div className="h-full" />}
        containerElement={<div className="h-96 w-full" />}
        mapElement={<div className="w-full h-full" />}
      />
      {currentMarker ? (
        <DetailView
          className="flex-1 h-96 w-full p-3"
          member={currentMarker}
          handleBack={handleBack}
        />
      ) : (
        <MapMembers
          className="flex-1 h-96 w-full p-3"
          members={locations}
          handleClick={handleClick}
          searchName={searchName}
          setSearchName={setSearchName}
          searchAddress={searchAddress}
          setSearchAddress={setSearchAddress}
          filterLocation={filterLocation}
          setIsActive={setIsActive}
          isActive={isActive}
        />
      )}
    </div>
  );
}
