import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import { ExternalLink } from '../Links/Links';

interface FeatureProps {
  title: string;
  feature: string;
  icon: IconName;
}

interface DivProps {
  bg?: string;
  bgColor: string;
  bgPosition: string;
}

export const FeatureBar2 = ({ manifestData }: any) => {
  library.add(fas);

  const featuresList = manifestData?.['features'];
  const colors = manifestData?.['stylesheet']['colors'];
  const config = manifestData?.['views']['featureBar2']['config'];

  if (!config) return <p>"Loading..."</p>;
  else {
    return (
      <Div
        bg={config?.bg}
        bgColor={config?.bgColor}
        bgPosition={config?.bgPosition}
        bgSize={config?.bgSize}
      >
        <FeatureDiv>
          {config.items?.map(
            ({ feature, title, icon }: FeatureProps, index: number) =>
              featuresList[feature]?.external === true ? (
                <ExternalFeature
                  href={featuresList[feature].url}
                  target="_blank"
                  rel="noopener noreferrer"
                  colors={colors}
                  key={title + index}
                >
                  <FontAwesomeIcon icon={['fas', icon]} fixedWidth={true} />
                  <FeatureTitle>{title}</FeatureTitle>
                </ExternalFeature>
              ) : (
                <Feature colors={colors} to={feature} key={title + index}>
                  <FontAwesomeIcon icon={['fas', icon]} fixedWidth={true} />
                  <FeatureTitle>{title}</FeatureTitle>
                </Feature>
              )
          )}
        </FeatureDiv>
      </Div>
    );
  }
};

export const Div = styled.div<DivProps>`
  display: flex;
  justify-content: center;
  background: ${({ bg, bgColor }) =>
    `linear-gradient(${bgColor} 100%, ${bgColor} 100%), url(${bg})`};
  background-size: ${({ bgSize }) => bgSize};
  padding: 1.25rem 0;
  background-position: ${({ bgPosition }) => bgPosition};
`;

export const FeatureDiv = styled.div`
  width: 1100px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  gap: 1rem;
  justify-content: center;
`;

export const Feature = styled(Link)<{ colors: any }>`
  text-transform: uppercase;
  font-family: Fjalla One, sans-serif;
  text-decoration: none;
  height: 200px;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  gap: 0.25rem;
  padding: 0.5rem;
  color: white;
  &:nth-child(odd) {
    background-color: ${({ colors }) => colors?.primary};
  }
  &:nth-child(even) {
    background-color: ${({ colors }) => colors?.tertiary};
  }

  &:hover {
    text-decoration: none;
    color: white;
  }

  font-size: 4em;

  @media (max-width: 1100px) {
    height: 150px;
    width: 150px;
    font-size: 3em;
  }
`;

export const ExternalFeature = styled(ExternalLink)<{ colors: any }>`
  text-transform: uppercase;
  font-family: Fjalla One, sans-serif;
  text-decoration: none;
  height: 200px;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  gap: 0.25rem;
  padding: 0.5rem;
  color: white;
  &:nth-child(odd) {
    background-color: ${({ colors }) => colors?.primary};
  }
  &:nth-child(even) {
    background-color: ${({ colors }) => colors?.tertiary};
  }

  &:hover {
    text-decoration: none;
    color: white;
  }

  font-size: 4em;

  @media (max-width: 1100px) {
    height: 150px;
    width: 150px;
    font-size: 3em;
  }
`;

export const FeatureTitle = styled.p`
  display: flex;
  align-items: center;
  height: 70px;
  font-size: 1.5rem;
  font-family: Fjalla One;

  @media (max-width: 1100px) {
    font-size: 0.9rem;
  }
`;
