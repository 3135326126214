import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import Pagination from '../Pagination';

interface IConfig {
  divideColor: string;
  additionalTailwindStyles: string;
  displayDate: string;
}

interface IViewData {
  url: string;
  title: string;
  type: 'newsDigestList';
  config: IConfig;
}

interface INewsDigestList {
  viewData: IViewData;
  digest: any;
  route: string;
  manifestData: any;
}

interface DigestImage {
  small?: string;
  large?: string;
}

interface BlogID {
  $id: string;
}

interface BlogDate {
  sec: number;
  usec: number;
}

interface ICalendar {
  epoch: number;
  type: 4;
  feedID: number;
  origin: number;
  eventID: string;
  calendarID: string;
  calendarTitle: string;
  title: string;
  content: any;
  summary: string;
  images: DigestImage[];
}

interface ITwitter {
  epoch: number;
  type: 2;
  feedID: number;
  origin: number;
  /**
   * The @ of the twitter user
   */
  title: string;
  /**
   * Content of twitter post. Can contain HTML prefer to use summary if possible
   */
  content: any;
  /**
   * Summary of the twitter post. Does not contain HTML. Cuts off after certain amount of characters.
   */
  summary: string;
  images: DigestImage[];
  /**
   * Profile image of twitter user. Used if tweet does not contain images.
   */
  profile_image_url_https: string;
  /**
   * ID of specific post. Append to link after the @ for the twitter user (remove @ symbol) and add /status/ between the two. EX: https://twitter.com/ocvtwitter/status/12341235145413
   */
  id_str: string;
  link: string;
}

interface IFacebook {
  epoch: number;
  type: 3;
  feedID: number;
  origin: number;
  /**
   * The PageID and PostID are contained in this key. UserID is before underscore. PostID is after underscore. EX: 109290340297987_0190234578001293. Use like this: https://facebook.com/109290340297987_0190234578001293
   */
  facebookPostID: string;
  /**
   * Facebook page/user title
   */
  title: string;
  /**
   * Content of twitter post. Can contain HTML prefer to use summary if possible
   */
  content: any;
  /**
   * Summary of the twitter post. Does not contain HTML. Cuts off after certain amount of characters.
   */
  summary: string;
  /**
   * Profile image of facebook page. Used if there are no images in the post.
   */
  fb_profile_image: string;
  status_type: string;
  images: DigestImage[];
}

interface IBlog {
  _id: BlogID;
  /**
   * Email of blog post creator.
   */
  creator: string;
  date: BlogDate;
  /**
   * Title of blog post.
   */
  title: string;
  /**
   * Content of blog post. Can contain HTML prefer to use summary.
   */
  content: any;
  /**
   * Different ID than the _id: { $id: number }
   */
  blogID: number;
  images: DigestImage[];
  status: number;
  epoch: number;
  type: 1;
  feedID: number;
  origin: number;
  /**
   * Summary of blog post
   */
  summary: string;
  /**
   * Title of the blog that this post came from
   */
  blogTitle: string;
  blogIdentifier: string;
}

interface IPush {
  epoch: number;
  type: 5;
  feedID: number;
  origin: number;
  /**
   * Title of push notification
   */
  title: string;
  channel: string;
  /**
   * Title of channel for push notification.
   */
  channelTitle: string;
  /**
   * Content of twitter post. Can contain HTML prefer to use summary if possible
   */
  content: any;
  /**
   * Summary of the twitter post. Does not contain HTML. Cuts off after certain amount of characters.
   */
  summary: string;
  images: DigestImage[];
}

type DigestFeed = Array<ITwitter | IFacebook | IBlog | IPush | ICalendar>;

export default function NewsDigestList({
  viewData,
  digest,
  route,
  manifestData,
}: INewsDigestList) {
  const [digestItemsPerPage, setDigestItemsPerPage] = useState<DigestFeed>([]);

  const onPageChanged = (data: { currentPage: number; pageLimit: number }) => {
    const { currentPage, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    setDigestItemsPerPage(digest?.slice(offset, offset + pageLimit));
  };

  return (
    <div
      style={{ flexGrow: 2 }}
      className={`flex flex-col place-items-center h-full w-full py-12 px-6 sm:px-8 md:px-12 lg:px-36 ${viewData?.config?.additionalTailwindStyles}`}
    >
      <div className="flex flex-col mt-12 space-y-6">
        {digestItemsPerPage
          ?.sort((a, b) => b.epoch - a.epoch)
          ?.map((item: ITwitter | IFacebook | IBlog | IPush | ICalendar) => (
            <div key={item?.epoch} className="w-full h-auto flex flex-col">
              <div className="bg-white overflow-hidden rounded-lg mx-2">
                <div className="relative rounded-lg bg-white px-4 py-2 mb-1 flex items-center space-x-2">
                  <div className="px-2 py-2 sm:px-4 sm:py-4">
                    {item.type === 1 && (
                      <FontAwesomeIcon
                        size="4x"
                        color="#7a7a7a"
                        icon={['far', 'newspaper']}
                      />
                    )}
                    {item.type === 2 && (
                      <FontAwesomeIcon
                        size="4x"
                        color="#1DA1F2"
                        icon={['fab', 'twitter-square']}
                      />
                    )}
                    {item.type === 3 && (
                      <FontAwesomeIcon
                        size="4x"
                        color="#4267B2"
                        icon={['fab', 'facebook-square']}
                      />
                    )}
                    {item.type === 4 && (
                      <FontAwesomeIcon
                        size="4x"
                        color="#7a7a7a"
                        icon={['far', 'calendar-alt']}
                      />
                    )}
                    {item.type === 5 && (
                      <FontAwesomeIcon
                        size="3x"
                        color="#7a7a7a"
                        icon={['far', 'bell']}
                      />
                    )}
                  </div>
                  <div className="flex-1 min-w-0">
                    {item.type === 1 && (
                      <div>
                        <p className="text-lg font-semibold text-gray-900">
                          {item.blogTitle}
                        </p>
                        <p className="text-lg text-gray-500 truncate">
                          New Post
                        </p>
                      </div>
                    )}
                    {item.type === 2 && (
                      <div>
                        <p className="text-lg font-semibold text-gray-900">
                          Twitter
                        </p>
                        <p className="text-lg text-gray-500 truncate">
                          {item.title}
                        </p>
                      </div>
                    )}
                    {item.type === 3 && (
                      <div>
                        <p className="text-lg font-semibold text-gray-900">
                          Facebook
                        </p>
                        <p className="text-lg text-gray-500 truncate">
                          {item.title}
                        </p>
                      </div>
                    )}
                    {item.type === 4 && (
                      <div>
                        <p className="text-lg font-semibold text-gray-900">
                          {item.calendarTitle}
                        </p>
                        <p className="text-lg text-gray-500 truncate">
                          {item.title}
                        </p>
                      </div>
                    )}
                    {item.type === 5 && (
                      <div>
                        <p className="text-lg font-semibold text-gray-900">
                          Notification
                        </p>
                        <p className="text-lg text-gray-500 truncate">
                          {item.channelTitle} - {item.channel}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-col text-left px-3 mb-3">
                  {item?.images?.length > 0 ? (
                    item?.images[0]?.large ? (
                      <Link
                        className={`no-underline hover:no-underline hover:text-current`}
                        to={`/${route}/${
                          item?.type === 1
                            ? item?._id?.$id
                            : item?.type === 2
                            ? item?.id_str
                            : item?.type === 3
                            ? item?.facebookPostID
                            : item?.type === 4
                            ? item?.eventID
                            : item?.type === 5
                            ? item?.epoch
                            : ''
                        }`}
                      >
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 h-auto w-full">
                          {item.type === 3 || item.type === 4 ? (
                            <div
                              className="break-words"
                              dangerouslySetInnerHTML={{
                                __html:
                                  item.summary.length === 200
                                    ? `${item.summary}...`
                                    : item.summary,
                              }}
                            ></div>
                          ) : (
                            <div className="flex flex-col space-y-1">
                              <p className="mt-3 text-lg font-semibold text-gray-900">
                                {item.title}
                              </p>
                              {item.type === 2 ? (
                                <p className="mt-2 text-lg font-medium text-gray-800 whitespace-normal break-words">
                                  {item.summary}
                                </p>
                              ) : (
                                <div
                                  className="break-words"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      item.summary.length === 200
                                        ? `${item.summary}...`
                                        : item.summary,
                                  }}
                                ></div>
                              )}
                            </div>
                          )}
                          <div
                            style={{
                              backgroundImage: `url(${item.images[0].large})`,
                            }}
                            className="w-full sm:mt-0 mt-2 sm:max-h-96 max-h-60 min-h-full sm:h-96 h-60 overflow-none rounded-sm bg-center bg-no-repeat bg-contain bg-clip-border "
                          ></div>
                        </div>
                      </Link>
                    ) : (
                      <Link
                        className={`no-underline hover:no-underline hover:text-current`}
                        to={`/${route}/${
                          item?.type === 1
                            ? item?._id?.$id
                            : item?.type === 2
                            ? item?.id_str
                            : item?.type === 3
                            ? item?.facebookPostID
                            : item?.type === 4
                            ? item?.eventID
                            : item?.type === 5
                            ? item?.epoch
                            : ''
                        }`}
                      >
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 h-auto w-full">
                          {item.type === 3 || item.type === 4 ? (
                            <div
                              className="break-words"
                              dangerouslySetInnerHTML={{
                                __html:
                                  item.summary.length === 200
                                    ? `${item.summary}...`
                                    : item.summary,
                              }}
                            ></div>
                          ) : (
                            <div className="flex flex-col space-y-1">
                              <p className="mt-3 text-lg font-semibold text-gray-900">
                                {item.title}
                              </p>
                              {item.type === 2 ? (
                                <p className="mt-2 text-lg font-medium text-gray-800 whitespace-normal break-words">
                                  {item.summary}
                                </p>
                              ) : (
                                <div
                                  className="break-words"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      item.summary.length === 200
                                        ? `${item.summary}...`
                                        : item.summary,
                                  }}
                                ></div>
                              )}
                            </div>
                          )}
                          <div
                            style={{
                              backgroundImage: `url(${item.images[0].small})`,
                            }}
                            className="w-full sm:mt-0 mt-2 sm:max-h-96 max-h-60 min-h-full sm:h-96 h-60 overflow-none rounded-sm bg-center bg-no-repeat bg-contain bg-clip-border "
                          ></div>
                        </div>
                      </Link>
                    )
                  ) : (
                    <Link
                      className={`no-underline hover:no-underline hover:text-current`}
                      to={`/${route}/${
                        item?.type === 1
                          ? item?._id?.$id
                          : item?.type === 2
                          ? item?.id_str
                          : item?.type === 3
                          ? item?.facebookPostID
                          : item?.type === 4
                          ? item?.eventID
                          : item?.type === 5
                          ? item?.epoch
                          : ''
                      }`}
                    >
                      <div className="flex h-auto w-full">
                        {item.type === 2 && (
                          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 h-auto w-full">
                            <p className="mt-2 text-lg font-medium text-gray-800 whitespace-normal break-words">
                              {item.summary}
                            </p>
                            <div
                              style={{
                                backgroundImage: `url(${item?.profile_image_url_https})`,
                              }}
                              className="w-full sm:mt-0 mt-2 sm:max-h-96 max-h-60 min-h-full sm:h-96 h-60 overflow-none rounded-sm bg-center bg-no-repeat bg-contain bg-clip-border "
                            ></div>
                          </div>
                        )}
                        {item.type === 3 && (
                          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 h-auto w-full">
                            <div
                              className="break-words"
                              dangerouslySetInnerHTML={{
                                __html:
                                  item.summary.length === 200
                                    ? `${item.summary}...`
                                    : item.summary,
                              }}
                            ></div>
                            <div
                              style={{
                                backgroundImage: `url(${item?.fb_profile_image})`,
                              }}
                              className="w-full sm:mt-0 mt-2 sm:max-h-96 max-h-60 min-h-full sm:h-96 h-60 overflow-none rounded-sm bg-center bg-no-repeat bg-contain bg-clip-border "
                            ></div>
                          </div>
                        )}
                        {item.type === 4 && (
                          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 h-auto w-full">
                            <div
                              className="break-words"
                              dangerouslySetInnerHTML={{
                                __html:
                                  item.summary.length === 200
                                    ? `${item.summary}...`
                                    : item.summary,
                              }}
                            ></div>
                            <div
                              style={{
                                backgroundImage: `url(${manifestData?.stylesheet?.images?.sheriffPatch?.url})`,
                              }}
                              className="w-full sm:mt-0 mt-2 sm:max-h-96 max-h-60 min-h-full sm:h-96 h-60 overflow-none rounded-sm bg-center bg-no-repeat bg-contain bg-clip-border "
                            ></div>
                          </div>
                        )}
                        {item.type === 1 && (
                          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 h-auto w-full">
                            <div className="flex flex-col space-y-1">
                              <p className="mt-3 text-lg font-semibold text-gray-900">
                                {item.title}
                              </p>
                              <div
                                className="break-words"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    item.summary.length === 200
                                      ? `${item.summary}...`
                                      : item.summary,
                                }}
                              ></div>
                            </div>
                            <div
                              style={{
                                backgroundImage: `url(${manifestData?.stylesheet?.images?.sheriffPatch?.url})`,
                              }}
                              className="w-full sm:mt-0 mt-2 sm:max-h-96 max-h-60 min-h-full sm:h-96 h-60 overflow-none rounded-sm bg-center bg-no-repeat bg-contain bg-clip-border "
                            ></div>
                          </div>
                        )}
                        {item.type === 5 && (
                          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 h-auto w-full">
                            <div className="flex flex-col space-y-1">
                              <p className="mt-3 text-lg font-semibold text-gray-900">
                                {item.title}
                              </p>
                              <div
                                className="break-words"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    item.summary.length === 200
                                      ? `${item.summary}...`
                                      : item.summary,
                                }}
                              ></div>
                            </div>
                            <div
                              style={{
                                backgroundImage: `url(${manifestData?.stylesheet?.images?.sheriffPatch?.url})`,
                              }}
                              className="w-full sm:mt-0 mt-2 sm:max-h-96 max-h-60 min-h-full sm:h-96 h-60 overflow-none rounded-sm bg-center bg-no-repeat bg-contain bg-clip-border "
                            ></div>
                          </div>
                        )}
                      </div>
                    </Link>
                  )}
                </div>
                {viewData?.config?.displayDate && (
                  <div className="flex flex-row px-1 py-2 justify-between border-t border-gray-200 mx-2">
                    <p className=" text- text-gray-500">
                      {new Date(item.epoch * 1000).toLocaleString([], {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </p>
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
      <div className="flex flex-row">
        <Pagination
          totalRecords={digest?.length}
          pageLimit={15}
          pageNeighbours={1}
          onPageChanged={onPageChanged}
        />
      </div>
    </div>
  );
}
