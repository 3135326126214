import * as React from 'react';
import { stylesheet } from 'typestyle';
import { Col, Row } from 'reactstrap';
import Paper from '@material-ui/core/Paper/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import * as moment from 'moment';
import { extendMoment } from 'moment-range';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swiper from 'react-id-swiper';
import Lightbox from 'react-images';
import IconButton from '@material-ui/core/IconButton';
import './OCVCalendar.css';

const Moment = extendMoment(moment);

const styles = stylesheet({
  icon: {
    fontFamily: "'Material Icons', serif",
    fontStyle: 'normal',
    display: 'inline-block',
    verticalAlign: 'middle',
    lineHeight: 1,
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    cursor: 'pointer',
    transition: '.15s ease-out',
    $nest: {
      '&:hover': {
        transform: 'scale(1.75)',
        transition: '.25s ease-out',
        color: '#1a8fff',
      },
      '&:first-of-type': {
        marginLeft: '1em',
      },
      '&:last-of-type': {
        marginRight: '1em',
      },
      '@media (max-width: 320px)': {
        marginLeft: '0em !important',
        marginRight: '0em !important',
      },
    },
  },
  cell: {
    position: 'relative',
    height: '5em',
    borderRight: '1px solid #eee',
    overflow: 'hidden',
    cursor: 'pointer',
    background: '#FFF',
    transition: '0.25s ease-out',
    $nest: {
      '&:hover': {
        background: '#f9f9f9',
        transition: '0.5s ease-out',
      },
      '&:last-child': {
        borderRight: 'none',
      },
    },
  },
  bodyRow: {
    borderBottom: '1px solid #eee',
    $nest: {
      '&:last-child': {
        borderRight: 'none',
        borderBottom: 'none',
      },
    },
  },
  bg: {
    fontWeight: 700,
    lineHeight: 1,
    color: '#1a8fff',
    opacity: 0,
    fontSize: '7.9em',
    position: 'absolute',
    top: '-.2em',
    right: '-.05em',
    transition: '.25s ease-out',
    letterSpacing: '-.07em',
    $nest: {
      '@media (max-width: 768px)': {
        fontSize: '2.45em',
        top: '1em',
      },
      '@media (max-width: 425px)': {
        fontSize: '2.1em',
        top: '1.25em',
      },
    },
  },
  bgSelected: {
    opacity: 0.05,
    transition: '.5s ease-in',
  },
  divider: {
    borderRight: '5px solid black',
    $nest: {
      '@media (max-width: 768px)': {
        borderRight: 'none',
      },
    },
  },
  links: {
    color: 'black',
    $nest: {
      '@media (max-width: 768px)': {
        color: '#0056b3',
        textDecoration: 'underline',
      },
    },
  },
  eventType: {
    color: 'grey',
    $nest: {
      '@media (max-width: 321px)': {
        position: 'relative',
      },
    },
  },
});

function Transition(props: any) {
  return <Slide direction="up" {...props} />;
}

const RegularMap = withScriptjs(
  withGoogleMap((props: any) => (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: props.latitude, lng: props.longitude }}
      defaultOptions={{
        scrollwheel: false,
      }}
    >
      <Marker position={{ lat: props.latitude, lng: props.longitude }} />
    </GoogleMap>
  ))
);

export default class OCVCalendar extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      currentMonth: Moment(new Date()),
      selectedDate: Moment(new Date()),
      modalOpen: false,
      currentEvent: null,
    };
    this.closeLightbox = this.closeLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
  }

  componentDidMount() {
    const { link } = this.props;
    try {
      fetch(link, { mode: 'cors' })
        .then((response) => response.json())
        .then((data) => {
          this.setState({ events: data });
        });
    } catch (e) {}
  }

  renderHeader() {
    const dateFormat = 'MMMM YYYY';
    return (
      <div className="OCVCalendarHeader OCVCalendarRow">
        <div className="OCVCalendarCol OCVCalenderColStart">
          <div className={styles.icon} onClick={this.prevMonth}>
            chevron_left
          </div>
        </div>
        <div className="OCVCalendarCol OCVCalendarColCenter">
          <span>{Moment(this.state.currentMonth).format(dateFormat)}</span>
        </div>
        <div
          className="OCVCalendarCol OCVCalendarColEnd"
          onClick={this.nextMonth}
        >
          <div className={styles.icon}>chevron_right</div>
        </div>
      </div>
    );
  }
  renderListHeader() {
    const dateFormat = 'MMMM DD, YYYY';
    return (
      <div className="OCVCalendarHeader OCVCalendarRow">
        <div className="OCVCalendarCol OCVCalenderColStart">
          <div className={styles.icon} onClick={this.prevDay}>
            chevron_left
          </div>
        </div>
        <div className="OCVCalendarCol OCVCalendarColCenter">
          <span>{Moment(this.state.selectedDate).format(dateFormat)}</span>
        </div>
        <div
          className="OCVCalendarCol OCVCalendarColEnd"
          onClick={this.nextDay}
        >
          <div className={styles.icon}>chevron_right</div>
        </div>
      </div>
    );
  }
  static renderDays() {
    const dateFormat = window.innerWidth >= 768 ? 'dddd' : 'ddd';
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="OCVCalendarCol OCVCalendarColCenter" key={i}>
          {Moment().weekday(i).format(dateFormat)}
        </div>
      );
    }
    return <div className="OCVCalendarRow OCVCalendarDays">{days}</div>;
  }

  renderCells() {
    const { currentMonth, selectedDate } = this.state;
    const monthStart = Moment(currentMonth).startOf('month');
    const monthEnd = Moment(monthStart).endOf('month');
    const startDate = Moment(monthStart).startOf('week');
    const endDate = Moment(monthEnd).endOf('week');
    const dateFormat = 'D';
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = '';

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = Moment(day).format(dateFormat);
        const cloneDay = day;
        days.push(
          <div
            className={
              'OCVCalendarCol ' +
              styles.cell +
              ' ' +
              (!Moment(day).isSame(monthStart, 'month')
                ? 'OCVCalendarDisabled'
                : '') +
              (Moment(day).isSame(selectedDate, 'day')
                ? 'OCVCalendarSelected'
                : '')
            }
            key={day.toString()}
            onClick={() => this.onDateClick(Moment(cloneDay))}
          >
            <span className="OCVCalendarNumber">{formattedDate}</span>
            {this.state.events &&
            this.getDayOfEvents(this.state.events, day) !== 0 &&
            Moment(day).isSame(monthStart, 'month') ? (
              <span className="OCVCalendarNumEvents">
                {this.getDayOfEvents(this.state.events, day) === 1
                  ? this.getDayOfEvents(this.state.events, day) +
                    (window.innerWidth >= 1000 ? ' Event' : '')
                  : this.getDayOfEvents(this.state.events, day) +
                    (window.innerWidth >= 1000 ? ' Events' : '')}
              </span>
            ) : (
              ''
            )}
            <span
              className={
                styles.bg +
                ' ' +
                (Moment(day).isSame(selectedDate, 'day')
                  ? styles.bgSelected
                  : '')
              }
            >
              {formattedDate}
            </span>
          </div>
        );
        day = Moment(day).add(1, 'days');
      }
      rows.push(
        <div
          className={'OCVCalendarRow ' + styles.bodyRow}
          key={day.toString()}
        >
          {days}
        </div>
      );
      days = [];
    }
    return <div>{rows}</div>;
  }
  onDateClick = (day: any) => {
    this.setState({ selectedDate: day });
  };
  getDayOfEvents = (events: any, day: moment.Moment) => {
    let numEvents = 0;
    for (let event in events) {
      if (events.hasOwnProperty(event)) {
        let startDate = moment.unix(events[event]['startDate']).startOf('day');
        let endDate = moment.unix(events[event]['endDate']).endOf('day');
        let range = Moment.range(startDate, endDate);
        if (range.contains(Moment(day))) {
          numEvents++;
        }
      }
    }
    return numEvents;
  };
  nextMonth = () => {
    this.setState({
      currentMonth: this.state.currentMonth.add(1, 'M'),
      selectedDate: this.state.selectedDate.add(1, 'M').date(1),
    });
  };
  prevMonth = () => {
    this.setState({
      currentMonth: this.state.currentMonth.subtract(1, 'M'),
      selectedDate: this.state.selectedDate.subtract(1, 'M').date(1),
    });
  };
  nextDay = () => {
    this.setState({
      selectedDate: this.state.selectedDate.add(1, 'days'),
      currentMonth:
        this.state.selectedDate.month() !== this.state.currentMonth.month()
          ? this.state.currentMonth.add(1, 'M')
          : this.state.currentMonth,
    });
  };
  prevDay = () => {
    this.setState({
      selectedDate: this.state.selectedDate.subtract(1, 'days'),
      currentMonth:
        this.state.selectedDate.month() !== this.state.currentMonth.month()
          ? this.state.currentMonth.subtract(1, 'M')
          : this.state.currentMonth,
    });
  };
  renderEvents = (events: any, day: moment.Moment) => {
    let listOfEvents: JSX.Element[] = [];
    for (let event in events) {
      let cloneEvent = events[event];
      if (events.hasOwnProperty(event)) {
        let startDate = moment.unix(events[event]['startDate']).startOf('day');
        let endDate = moment.unix(events[event]['endDate']).endOf('day');
        let range = Moment.range(startDate, endDate);
        if (range.contains(Moment(day))) {
          listOfEvents.push(
            <Paper
              onClick={() => this.showModal(cloneEvent)}
              className="OCVCalendarEvent"
              id={events[event]['eventID']}
              style={{
                borderLeft: '10px solid ' + events[event].type.category,
              }}
            >
              <Row>
                <Col
                  style={{ paddingBottom: '10px' }}
                  className={styles.divider}
                  xs={{ size: 12, order: 2 }}
                  sm={{ size: 12, order: 2 }}
                  md={{ size: 3, order: 1 }}
                >
                  <div>
                    <p>
                      Start:
                      <br />
                      <i>
                        {moment
                          .unix(events[event]['startDate'])
                          .format('M/D/yyyy h:mm A')}
                      </i>
                    </p>
                  </div>
                  <div>
                    <p>
                      End:
                      <br />
                      <i>
                        {moment
                          .unix(events[event]['endDate'])
                          .format('M/D/yyyy  h:mm A')}
                      </i>
                    </p>
                  </div>
                </Col>
                <Col
                  style={{ paddingBottom: '10px' }}
                  xs={{ size: 12, order: 1 }}
                  sm={{ size: 12, order: 1 }}
                  md={{ size: 9, order: 2 }}
                >
                  <h2>{events[event].title}</h2>
                  {events[event].subtitle && (
                    <h3 style={{ color: 'grey' }}>
                      <i>{events[event].subtitle}</i>
                    </h3>
                  )}
                </Col>
                {events[event].type.title && (
                  <Col
                    xs={{ size: 12, order: 3 }}
                    sm={{ size: 12, order: 3 }}
                    md={12}
                  >
                    <h3
                      style={{
                        color: 'grey',
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                      }}
                    >
                      Type: <i>{events[event].type.title}</i>
                    </h3>
                  </Col>
                )}
              </Row>
            </Paper>
          );
        }
      }
    }
    if (listOfEvents.length === 0) {
      listOfEvents.push(
        <Paper className="OCVCalendarEvent">
          <Row>
            <Col xs={12}>
              <p style={{ textAlign: 'center' }}>
                No events are scheduled for this day.
              </p>
            </Col>
          </Row>
        </Paper>
      );
    }
    return listOfEvents;
  };
  renderEventModal = (eventData: any) => {
    const params = {
      parallax: true,
      slidesPerView: 1,
      speed: 3000,
      loop: true,
      autoplay: {
        disableOnInteraction: true,
      },
      effect: 'fade',
    };
    let images: any[] = [];
    for (let i = 0; i < eventData.images.length; i++) {
      images.push({ src: eventData.images[i].large });
    }
    return (
      <Dialog
        open={this.state.modalOpen}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={'md'}
        onClose={() => this.closeModal()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby={eventData.eventID}
      >
        <DialogTitle
          style={{ padding: '10px' }}
          id={'alert-dialog-slide-title'}
        >
          <IconButton
            aria-label="Close"
            className="OCVCalendarCloseButton"
            onClick={() => this.closeModal()}
          >
            <FontAwesomeIcon icon={['fas', 'times']} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Row>
            <Col xs={10}>
              {parseFloat(eventData.latitude) !== 0 &&
              parseFloat(eventData.longitude) !== 0 ? (
                <RegularMap
                  latitude={parseFloat(eventData.latitude)}
                  longitude={parseFloat(eventData.longitude)}
                  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAEOZZNm6jFVe3j3ISl_Ha9BeODMYrdqaY"
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={
                    <div
                      style={{
                        height: `280px`,
                        borderRadius: '6px',
                        overflow: 'hidden',
                      }}
                    />
                  }
                  mapElement={<div style={{ height: `100%` }} />}
                />
              ) : (
                ''
              )}
              <h2>
                {eventData.hasOwnProperty('title') ? eventData['title'] : ''}
              </h2>
              {eventData.subtitle ? (
                <h3 style={{ color: 'grey' }}>
                  <i>{eventData.subtitle}</i>
                </h3>
              ) : (
                ''
              )}
              {eventData.type.title && (
                <h3 className={styles.eventType}>
                  Type: <i>{eventData.type.title}</i>
                </h3>
              )}
              {eventData.facebookLink ? (
                <a
                  style={{ padding: '0 5px' }}
                  target={'_blank'}
                  rel="noopener noreferrer"
                  href={eventData.facebookLink}
                >
                  <FontAwesomeIcon icon={['fab', 'facebook']} />
                </a>
              ) : (
                ''
              )}
              {eventData.twitterHandle ? (
                <a
                  style={{ padding: '0 5px' }}
                  target={'_blank'}
                  rel="noopener noreferrer"
                  href={'https://twitter.com/' + eventData.twitterHandle}
                >
                  <FontAwesomeIcon icon={['fab', 'twitter']} />
                </a>
              ) : (
                ''
              )}
              {eventData.address ? (
                <Col
                  style={{ textAlign: 'left', padding: '10px 0' }}
                  xs={12}
                  sm={12}
                  md={6}
                >
                  <h3>Location</h3>
                  <div>{eventData.address}</div>
                  <a
                    target={'_blank'}
                    rel="noopener noreferrer"
                    className={styles.links}
                    href={'http://maps.google.com/?q=' + eventData.address}
                  >
                    Directions
                  </a>
                </Col>
              ) : (
                ''
              )}
              {eventData.description ? (
                <p
                  style={{ padding: '15px 0' }}
                  dangerouslySetInnerHTML={{ __html: eventData.description }}
                />
              ) : (
                ''
              )}
              <Row style={{ justifyContent: 'flex-start' }}>
                {eventData.links.length !== 0 ? (
                  <Col
                    style={{ textAlign: 'left', padding: '10px 0' }}
                    xs={12}
                    sm={12}
                    md={6}
                  >
                    <h3>Links</h3>
                    {eventData.links.map((link: any) => (
                      <div>
                        <a className={styles.links} href={link.link}>
                          {link.name}
                        </a>
                      </div>
                    ))}
                  </Col>
                ) : (
                  ''
                )}
                {eventData.email.length !== 0 ? (
                  <Col
                    style={{ textAlign: 'left', padding: '10px 0' }}
                    xs={12}
                    sm={12}
                    md={6}
                  >
                    <h3>Email</h3>
                    {eventData.email.map((email: any) => (
                      <div>
                        <a
                          className={styles.links}
                          href={'mailto:' + email.email}
                        >
                          {email.name}
                        </a>
                      </div>
                    ))}
                  </Col>
                ) : (
                  ''
                )}
                {eventData.phone.length !== 0 ? (
                  <Col
                    style={{ textAlign: 'left', padding: '10px 0' }}
                    xs={12}
                    sm={12}
                    md={6}
                  >
                    <h3>Phone</h3>
                    {eventData.phone.map((phone: any) => (
                      <div>
                        <a
                          className={styles.links}
                          href={'tel:' + phone.number}
                        >
                          {phone.name}
                        </a>
                      </div>
                    ))}
                  </Col>
                ) : (
                  ''
                )}
                {eventData.tags.length !== 0 ? (
                  <Col
                    style={{ textAlign: 'left', padding: '10px 0' }}
                    xs={12}
                    sm={12}
                    md={6}
                  >
                    <h3>Tags</h3>
                    {eventData.tags.map((tag: any, index: number) => (
                      <span>
                        {index === eventData.tags.length - 1
                          ? tag.name
                          : tag.name + ', '}
                      </span>
                    ))}
                  </Col>
                ) : (
                  ''
                )}
              </Row>
              {eventData.images.length !== 0 ? (
                <Swiper {...params}>
                  {eventData.images.length !== 0
                    ? eventData.images.map(
                        (
                          image: { large: string; small: string },
                          index: number
                        ) => (
                          <img
                            alt={'Image' + index}
                            onClick={(e) => this.openLightbox(e, index)}
                            key={index}
                            src={
                              eventData.images.length !== 0 ? image.large : ' '
                            }
                          />
                        )
                      )
                    : ''}
                </Swiper>
              ) : (
                ''
              )}
              <Lightbox
                onClickNext={this.gotoNext}
                onClickPrev={this.gotoPrevious}
                backdropClosesModal
                currentImage={this.state.currentImage}
                onClose={this.closeLightbox}
                isOpen={this.state.lightboxIsOpen}
                images={images}
              />
            </Col>
          </Row>
        </DialogContent>
      </Dialog>
    );
  };
  openLightbox = (event: { preventDefault: () => void }, index: number) => {
    event.preventDefault();
    this.setState({ currentImage: index, lightboxIsOpen: true });
  };
  closeLightbox = () => {
    this.setState({ lightboxIsOpen: false });
  };
  gotoPrevious = () => {
    this.setState({ currentImage: this.state.currentImage - 1 });
  };
  gotoNext = () => {
    this.setState({ currentImage: this.state.currentImage + 1 });
  };
  showModal = (eventData: any) => {
    this.setState({ modalOpen: true, currentEvent: eventData });
  };
  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  render() {
    const config = this.props?.viewData?.config;
    let backgroundImageConfig = undefined;

    if (config?.rgba1 && config?.rgba2 && config?.backgroundImageURL)
      backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2}), url(${config.backgroundImageURL})`;
    else if (config?.rgba1 && config?.rgba2)
      backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2})`;
    else if (config?.backgroundImageURL)
      backgroundImageConfig = `url(${config.backgroundImageURL})`;

    return (
      <div
        id={this.props.anchorID}
        className={'bg-cover ' + config?.additionalTailwindStyles}
        style={{
          flexGrow: 2,
          backgroundColor: '#eee',
          backgroundImage: backgroundImageConfig,
        }}
      >
        <Row>
          <Col style={{ paddingTop: '50px' }} xs={10}>
            <Paper style={{ padding: '20px', marginBottom: '15px' }}>
              <div className="OCVCalendar">
                {this.renderHeader()}
                {OCVCalendar.renderDays()}
                {this.renderCells()}
              </div>
            </Paper>
          </Col>
        </Row>
        <Row>
          <Col style={{ paddingTop: '50px' }} xs={10}>
            <Paper style={{ padding: '20px', marginBottom: '15px' }}>
              <div className="OCVCalendar">
                {this.renderListHeader()}
                {this.renderEvents(this.state.events, this.state.selectedDate)}
              </div>
            </Paper>
          </Col>
        </Row>
        {this.state.currentEvent
          ? this.renderEventModal(this.state.currentEvent)
          : ''}
      </div>
    );
  }
}
