import ocv from "../../../assets/OCV_LLC@2x.png";
import govApp from "../../../assets/TheGOVApp Wordmark Light.png";
import sheriff from "../../../assets/Sheriff_White.png";
import ema from "../../../assets/Ema_logo_white.png";
import fire from "../../../assets/Fire_Long_V2.png";
import police from "../../../assets/Police_Long.png";
import publicHealth from "../../../assets/Public_Health_Long.png";
import * as S from "./styles";

interface IVerticalMarket {
  [id: string]: {
    [id2: string]: any;
  };
}

let verticalMarkets: IVerticalMarket = {
  sheriff: {
    href: "https://thesheriffapp.com", // dark option to be handled elsewhere
    alt: "The Sheriff App",
    img: sheriff,
  },
  police: {
    href: "https://thepoliceapp.com",
    alt: "The Police App",
    img: police,
  },
  ema: {
    href: "https://myemaapp.com",
    alt: "My EMA App",
    img: ema,
  },
  fire: {
    href: "https://thefiredepartmentapp.com",
    alt: "The Fire Department App",
    img: fire,
  },
  health: {
    href: "https://thepublichealthapp.com",
    alt: "The Public Health App",
    img: publicHealth,
  },
  ocv: {
    href: "https://ocvapps.com",
    alt: "OCV Apps",
    img: ocv,
  },
  govApp: {
    href: "https://ocvapps.com",
    alt: "Gov Apps",
    img: govApp,
  },
};

const Footer2 = ({ manifestData, footerConfig, anchorID }: any) => {
  const navConfig = manifestData?.["views"]["navbar"]["config"];
  const primary = manifestData?.["stylesheet"]["colors"]["primary"];
  const logo = manifestData["stylesheet"]?.images[footerConfig.icon]["url"];
  const altText =
    manifestData["stylesheet"]?.images[footerConfig.icon]["altText"];

  return (
    <S.Container id={anchorID} $bgColor={primary}>
      <S.County>
        <img height={56} width={56} src={logo} alt={altText} />
        <S.Title
          className="text-white"
          dangerouslySetInnerHTML={{
            __html: navConfig?.title,
          }}
        />
      </S.County>
      <S.Links>
        <a href="https://myocv.com/accessibility">Accessibility</a>
        <a href="https://myocv.com/eula">EULA</a>
        <a href="https://myocv.com/privacyPolicy">Privacy Policy</a>
      </S.Links>
      <S.CountyInfo>
        <a
          title="Click to open on Google Maps"
          rel="noopener noreferrer"
          target="_blank"
          href={`http://maps.google.com/?q=${footerConfig?.address}`}
          dangerouslySetInnerHTML={{
            __html: footerConfig?.address,
          }}
        />
        <a
          className=""
          href={`tel:${footerConfig.phone}`}
          title={`Call us at ${footerConfig.phone}`}
        >
          {footerConfig.phone}
        </a>
        <p>{footerConfig.businessHours}</p>
        <p>{`COPYRIGHT © ${new Date().getFullYear()} ${footerConfig.title.toUpperCase()} & OCV, LLC`}</p>
      </S.CountyInfo>
      <S.PoweredBy>
        <p>Powered By: </p>
        <a
          rel="noopener noreferrer"
          href={
            verticalMarkets[footerConfig.vertical]
              ? verticalMarkets[footerConfig.vertical].href
              : verticalMarkets["ocv"].href
          }
          target={"_blank"}
        >
          <img
            width={160}
            className="footerImgOne mx-auto"
            src={verticalMarkets[footerConfig.vertical].img}
            alt={verticalMarkets[footerConfig.vertical].alt}
          />
        </a>
      </S.PoweredBy>
    </S.Container>
  );
};

export default Footer2;
