import styled from 'styled-components';
import { ButtonLink, ExternalLink, StyledLink } from '../Links/Links';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { MobileDropdown } from './MobileDropdown/MobileDropdown';
import * as S from './styles';
const Color = require('color');

export const AccordionLink = styled(StyledLink)`
  font-size: 18px;
  font-weight: 400;
  list-style: none;
  font-family: Roboto;
  padding: 1rem 0.75rem 1rem 0.75rem;
  cursor: pointer;
  display: block;
`;

export const ExternalAccordionLink = styled(ExternalLink)`
  font-size: 18px;
  font-weight: 400;
  list-style: none;
  font-family: Roboto;
  padding: 1rem 0.75rem 1rem 0.75rem;
  cursor: pointer;
  display: block;
`;

export const MobileNav = ({ manifestData }: any) => {
  library.add(fab);

  const images = manifestData?.['stylesheet']['images'];
  const featuresList = manifestData?.['features'];
  const config: any = manifestData?.['views']['navbar']['config'];

  const primaryColor = Color(manifestData?.['stylesheet']['colors']['primary']);
  const tertiaryColor = manifestData?.['stylesheet']['colors']['tertiary'];
  const subColor = primaryColor.lightness(15);
  const nestedSubColor = subColor.lightness(25);

  if (!config) return 'Loading..';
  return (
    config && (
      <S.Nav primary={primaryColor} tertiary={tertiaryColor}>
        <StyledLink to="/">
          <img
            src={images['sheriffPatch']['url']}
            alt={images['sheriffPatch']['altText']}
            width="75"
          />
        </StyledLink>
        <S.MenuToggle id="menu__toggle" type="checkbox" />
        <S.MenuButton htmlFor="menu__toggle">
          <span></span>
          <S.Main menuColor={primaryColor}>
            {config.items?.map((item: any) =>
              featuresList[item].type === 'submenu' ||
              featuresList[item].type === 'megaMenu' ? (
                <MobileDropdown
                  featuresList={featuresList}
                  item={item}
                  key={item?.title}
                  subColor={subColor}
                  nestedSubColor={nestedSubColor}
                />
              ) : featuresList[item]?.external === true ? (
                <ExternalAccordionLink
                  href={featuresList[item].url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {featuresList[item].title}
                </ExternalAccordionLink>
              ) : (
                <AccordionLink to={item} key={item.title}>
                  {featuresList[item].title}
                </AccordionLink>
              )
            )}
          </S.Main>
          <S.Socials primary={primaryColor} tertiary={tertiaryColor}>
            {config?.socialMedia?.map(
              (item: { icon: IconName; url: string }) => (
                <ExternalLink href={item.url} key={item.icon} external>
                  <FontAwesomeIcon icon={['fab', item.icon]} size="2x" />
                </ExternalLink>
              )
            )}
          </S.Socials>
        </S.MenuButton>
      </S.Nav>
    )
  );
};
