import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { withRouter } from 'react-router';
import Swiper from 'react-id-swiper';
import * as moment from 'moment';
import Paper from '@material-ui/core/Paper/Paper';
import Lightbox from 'react-images';
import './OCVAlertsDetail.css';

export default withRouter(function OCVAlertsDetail(props: any) {
  const [currentImage, setCurrentImage] = useState(0);
  const [lightboxIsOpen, setLightbox] = useState(false);

  const openLightbox = (
    event: { preventDefault: () => void },
    index: number
  ) => {
    event.preventDefault();
    setCurrentImage(index);
    setLightbox(true);
  };
  const closeLightbox = () => {
    setLightbox(false);
  };
  const gotoPrevious = () => {
    setCurrentImage(currentImage - 1);
  };
  const gotoNext = () => {
    setCurrentImage(currentImage + 1);
  };

  const { data, match, route, config } = props;
  let test = data[match.params.id];
  let images: any[] = [];
  for (let i = 0; i < test.images.length; i++) {
    images.push({ src: test.images[i].large });
  }
  const params = {
    parallax: true,
    slidesPerView: 1,
    speed: 3000,
    loop: true,
    autoplay: {
      disableOnInteraction: true,
    },
    effect: 'fade',
  };
  return (
    <Row className={config?.additionalTailwindStyles} style={{ flexGrow: 2 }}>
      <Col className="OCVAlertsDetailColOne" xs={10}>
        <button
          className={'OCVAlertsDetailButton btn btn-primary'}
          onClick={() => props.history.push('/' + route)}
        >
          &larr; Back to List
        </button>
        <Paper className="OCVAlertsDetailPaper">
          <h1>{test.push}</h1>
          {!('epoch' in test) ? (
            ''
          ) : (
            <span className="OCVAlertsDetailSpanOne">
              <strong>
                Posted on{' '}
                {moment
                  .unix(test.epoch !== null ? test['epoch'] : ' ')
                  .format('LL')}
              </strong>
            </span>
          )}
          <div dangerouslySetInnerHTML={{ __html: test.description }} />
          <span className="OCVAlertsDetailSpanTwo">
            {'Channel: ' + test.channelTitle}
          </span>
        </Paper>
      </Col>
      {test.images.length !== 0 ? (
        <Col className="OCVAlertsDetailColTwo" xs={10} sm={8} md={4}>
          <Swiper {...params}>
            {test.images.map(
              (image: { large: string; small: string }, index: number) => (
                <img
                  alt={image.large}
                  onClick={(e) => openLightbox(e, index)}
                  key={index}
                  src={test.images.length !== 0 ? image.large : ' '}
                />
              )
            )}
          </Swiper>
          <Lightbox
            onClickNext={gotoNext}
            onClickPrev={gotoPrevious}
            backdropClosesModal
            currentImage={currentImage}
            onClose={closeLightbox}
            isOpen={lightboxIsOpen}
            images={images}
          />
        </Col>
      ) : (
        ''
      )}
    </Row>
  );
});
