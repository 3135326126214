import SocialIcons from "../SocialIcons/SocialIcons";
import React from "react";
export default function SocialBar(props: any) {
  const config = props.viewData?.config;
  let backgroundImageConfig = undefined;

  if (config?.rgba1 && config?.rgba2 && config?.backgroundImageURL)
    backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2}), url(${config.backgroundImageURL})`;
  else if (config?.rgba1 && config?.rgba2)
    backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2})`;
  else if (config?.backgroundImageURL)
    backgroundImageConfig = `url(${config.backgroundImageURL})`;

  return (
    <div
      id={props?.anchorID}
      className={` w-screen bg-opacity-25 bg-cover ${config?.additionalTailwindStyles}`}
      style={{
        background: props?.viewData?.backgroundColor
          ? props.viewData.backgroundColor
          : "#eee",
        backgroundImage: backgroundImageConfig,
      }}
    >
      <div className='max-w-7xl p-3 mx-auto'>
        <div className='flex flex-row justify-center'>
          <div className='flex items-center justify-center md:space-x-10 flex-col md:flex-row'>
            <h2
              style={{
                color: props?.viewData?.titleColor,
                fontFamily: "Fjalla One, sans-serif",
              }}
              className=' mx-auto text-2xl sm:text-3xl text-center font-extrabold lg:max-w-xl lg:text-left uppercase'
            >
              {props?.viewData?.title}
            </h2>
            <div className='flex flex-row  md:space-y-0 md:flex-wrap justify-center space-x-6 md:space-x-3'>
              {props.viewData.socials.map((item: any) => (
                <SocialIcons
                  props={item}
                  divClasses={
                    "flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-2 items-center py-1 sm:py-0"
                  }
                  linkClasses={
                    "font-bold text-xl sm:text-2xl space-x-2 flex items-center"
                  }
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
