import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withFormsy } from 'formsy-react';
import './OCVFormComponents.css';

export default withFormsy(function OCVTextInput(props: any) {
  const changeValue = (event: { currentTarget: { value: any } }) => {
    // setValue() will set the value of the component, which in
    // turn will validate it and the rest of the form
    // Important: Don't skip this step. This pattern is required
    // for Formsy to work.
    props.setValue(event.currentTarget.value);
  };

  const { label, getValue, required, isValidValue } = props;
  return (
    <div className="OCVFormComponentsDiv">
      <TextField
        label={label}
        onChange={changeValue}
        fullWidth
        value={getValue() || ''}
        variant={'outlined'}
        required={required}
      />

      <span className="OCVFormComponentsSpan">{isValidValue(getValue())}</span>
    </div>
  );
});
