import React, { useState, useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import { Col, Row } from 'reactstrap';
import Pagination from '../../../MainComponents/Pagination';
import Paper from '@material-ui/core/Paper/Paper';
import * as moment from 'moment';
import { Link, withRouter } from 'react-router-dom';
import './OCVAlertsContainer.css';

export default withRouter(function OCVAlertsContainer(props: any) {
  const [data, setData] = useState(props.data);
  const [currentEntries, setCurrentEntries] = useState(props.data.slice(0, 10));
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const MyLoader = () => (
    <ContentLoader>
      {/* Only SVG shapes */}
      <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
      <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
      <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
    </ContentLoader>
  );
  const onPageChanged = (incomingData: {
    currentPage: number;
    totalPages: number;
    pageLimit: number;
  }) => {
    const { currentPage, pageLimit } = incomingData;
    const offset = (currentPage - 1) * pageLimit;
    const currentEntries = data.slice(offset, offset + pageLimit);
    setCurrentEntries(currentEntries);
    setCurrentPage(currentPage);
  };

  const { route, anchorID, config } = props;
  return (
    <>
      <Row
        id={anchorID}
        className={config?.additionalTailwindStyles}
        style={{ flexGrow: 2 }}
      >
        {currentEntries.length !== 0 ? (
          currentEntries.map((item: any, index: any) => (
            <Col
              key={route + index + 'alert'}
              className="OCVAlertsContainerColOne"
              xs={10}
              sm={10}
              md={10}
            >
              <Paper className="OCVAlertsContainerPaperOne">
                <div className="OCVAlertsContainerExternalDiv">
                  <div className="OCVAlertsContainerInternalDiv">
                    <h4>{item.push}</h4>
                    <span className="OCVAlertsContainerSpanOne">
                      <strong>
                        Posted on {moment.unix(item.epoch).format('LLLL')}
                      </strong>
                    </span>
                    <div
                      className="OCVAlertsContainerNestedDiv"
                      dangerouslySetInnerHTML={{ __html: item.summary }}
                    />
                    <Link
                      className="OCVAlertsContainerLink"
                      to={{
                        pathname: `/${route}/${index + (currentPage - 1) * 10}`,
                      }}
                    >
                      Read More
                    </Link>
                    <span className="OCVAlertsContainerSpanTwo">
                      {'Channel: ' + item.channelTitle}
                    </span>
                  </div>
                </div>
              </Paper>
            </Col>
          ))
        ) : data.length === 0 ? (
          <Col className="OCVAlertsContainerColTwo" xs={10} sm={10} md={10}>
            <Paper className="OCVAlertsContainerPaperTwo">
              <h3>There are no alerts at this time.</h3>
            </Paper>
          </Col>
        ) : (
          <Col className="OCVAlertsContainerColThree" xs={10} sm={10} md={10}>
            <Paper className="OCVAlertsContainerPaperThree">
              <MyLoader />
            </Paper>
          </Col>
        )}
      </Row>
      <Row>
        <Col xs={10} sm={10} md={8}>
          <Pagination
            totalRecords={data.length}
            pageLimit={10}
            pageNeighbours={1}
            onPageChanged={onPageChanged}
          />
        </Col>
      </Row>
    </>
  );
});
