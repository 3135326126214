import React from 'react';
import { Col, Row } from 'reactstrap';
import Paper from '@material-ui/core/Paper/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { withRouter } from 'react-router';
import './OCVLargeSubmenuPage.css';

export default withRouter(function OCVLargeSubmenuPage(props: any) {
  const { items, manifestData, classes, history, anchorID, config } = props;

  return (
    <Row
      id={anchorID}
      className={config?.additionalTailwindStyles}
      style={{ flexGrow: 2 }}
    >
      <Col className="OCVLargeSubmenuPageCol" xs={10}>
        <Paper className="OCVLargeSubmenuPagePaper">
          <div>
            {items.map((item: any) => {
              return (
                <div className="mb-8">
                  <h2 className="text-2xl font-bold text-center">
                    {item.title}
                  </h2>
                  <List>
                    {item.features.map((feature: any, i: any) =>
                      manifestData['features'][feature]['subtype'] ? (
                        manifestData['features'][feature]['subtype'][0] ===
                        'external' ? (
                          <li>
                            <a
                              style={{ textDecoration: 'none' }}
                              className="w-full h-full"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={manifestData['features'][feature]['url']}
                            >
                              <ul>
                                <ListItem
                                  key={feature}
                                  className={
                                    'OCVLargeSubmenuPageListItem ' +
                                    classes.homepageButton
                                  }
                                >
                                  {manifestData['features'][feature]['title']}
                                </ListItem>
                              </ul>
                            </a>
                          </li>
                        ) : (
                          <ListItem
                            key={feature}
                            className={
                              'OCVLargeSubmenuPageListItem ' +
                              classes.homepageButton
                            }
                            onClick={() => history.replace('/' + feature)}
                          >
                            {manifestData['features'][feature]['title']}
                          </ListItem>
                        )
                      ) : (
                        <ListItem
                          key={feature}
                          className={
                            'OCVLargeSubmenuPageListItem ' +
                            classes.homepageButton
                          }
                          onClick={() => history.replace('/' + feature)}
                        >
                          {manifestData['features'][feature]['title']}
                        </ListItem>
                      )
                    )}
                  </List>
                </div>
              );
            })}
          </div>
        </Paper>
      </Col>
    </Row>
  );
});
