import React from 'react';
import { HeaderBorder } from '../../OCVFeatures/PossibilityBox/PossibilityBox';

export default function PageView(props: any) {
  const [pageData, setPageData] = React.useState<any>([]);
  React.useEffect(() => {
    fetch(props.manifestData['features'][props.viewData['feature']]['url'], {
      mode: 'cors',
    })
      .then((response) => response.json())
      .then((data) => {
        setPageData(data);
      });
  }, [props.manifestData, props.viewData]);

  const config = props.viewData?.config;
  let backgroundImageConfig = undefined;

  if (config?.rgba1 && config?.rgba2 && config?.backgroundImageURL)
    backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2}), url(${config.backgroundImageURL})`;
  else if (config?.rgba1 && config?.rgba2)
    backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2})`;
  else if (config?.backgroundImageURL)
    backgroundImageConfig = `url(${config.backgroundImageURL})`;

  return (
    <div
      id={props.anchorID}
      className={`bg-cover ${config?.additionalTailwindStyles}`}
      style={{
        backgroundImage: backgroundImageConfig,
      }}
    >
      {props.manifestData['features'][props.viewData['feature']]?.subtype[0] ===
        'imageAtTop' && (
        <>
          {pageData?.data?.images[0] ? (
            <div className="relative mb-4">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 flex flex-row justify-center">
                <img
                  className="relative object-contain w-full max-h-80"
                  src={pageData?.data?.images[0]?.large}
                  alt={props?.viewData?.alt}
                />
              </div>
            </div>
          ) : null}
          <div className="pt-8">
            <div className="text-center mx-auto max-w-7xl ">
              <h1
                style={{ fontFamily: 'Fjalla One' }}
                className="text-2xl flex flex-col items-center tracking-tight font-extrabold sm:text-3xl md:text-4xl"
              >
                {pageData?.data?.title}
              </h1>
              <div
                dangerouslySetInnerHTML={{ __html: pageData?.data?.content }}
                className="mt-3 text-sm sm:text-base md:mt-5 md:text-lg "
              ></div>
            </div>
          </div>
        </>
      )}

      {props.manifestData['features'][props.viewData['feature']]?.subtype[0] !==
        'imageAtTop' && (
        <>
          <div className="p-8">
            <div className="text-center mt-3 max-w-md mx-auto text-base sm:text-lg md:mt-5 md:text-xl md:max-w-2xl xl:max-w-6xl">
              <h1
                style={{ fontFamily: 'Fjalla One' }}
                className="text-2xl md:text-3xl lg:text-4xl flex flex-col items-center tracking-tight font-extrabold"
              >
                {pageData?.data?.title}
              </h1>
              <div
                dangerouslySetInnerHTML={{ __html: pageData?.data?.content }}
                className="mt-3 text-sm sm:text-base md:mt-5 md:text-lg w-10/12 2xl:w-4/5 mx-auto font-semibold"
              ></div>
            </div>
          </div>
          {pageData?.data?.images[0] ? (
            <div className="relative mt-4">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 flex flex-row justify-center">
                <img
                  className="relative object-contain w-full max-h-56 mb-10 pb-3"
                  src={pageData?.data?.images[0]?.large}
                  alt={props?.viewData?.alt}
                />
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}
