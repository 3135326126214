import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Paper from '@material-ui/core/Paper/Paper';
import TextField from '@material-ui/core/TextField';
import OCVBlogList from '../OCVBlogList/OCVBlogList';
import './OCVBlogContainer.css';

export default withRouter(function OCVBlogContainer(props: any) {
  const [blogData, setBlogData] = useState(props.blogData);
  const [filteredData, setFilteredData] = useState(props.blogData);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setFilteredData(props.blogData);
    setBlogData(props.blogData);
  }, [props.blogData]);

  const handleSearch = (event: { target: { value: any } }) => {
    let newItems = search;
    if (event.target.value !== '') {
      newItems = blogData.filter((entry: any) => {
        let lowerEntry = event.target.value.toLowerCase();
        let title = entry.title.toLowerCase();
        let content = entry.content.toLowerCase();
        return title.includes(lowerEntry) || content.includes(lowerEntry);
      });
      setSearch(event.target.value);
      setFilteredData(newItems);
    } else {
      setSearch(event.target.value);
    }
  };
  const clearFilters = () => {
    setSearch('');
    setFilteredData(props.blogData);
  };

  const {
    classes,
    route,
    subtypes,
    submitATipLink,
    perPage,
    anchorID,
    config,
  } = props;
  return (
    <>
      <Row
        id={anchorID}
        className={'OCVBlogContainerRow ' + config?.additionalTailwindStyles}
        style={{ flexGrow: 2 }}
      >
        <Col xs={10} sm={10} md={10}>
          <Paper className="OCVBlogContainerPaper">
            <TextField
              value={search}
              fullWidth
              id="Search"
              inputProps={{ 'aria-label': 'Search for entries in Blog' }}
              InputLabelProps={{ 'aria-label': 'Search for entries in Blog' }}
              onChange={handleSearch}
              label={'Search...'}
              placeholder={'Search...'}
              variant={'outlined'}
            />
            <button
              className="OCVBlogContainerButton btn btn-primary btn-block"
              onClick={clearFilters}
            >
              Clear Search
            </button>
          </Paper>
        </Col>
        <OCVBlogList
          classes={classes}
          route={route}
          search={search}
          handleSearch={handleSearch}
          clearFilters={clearFilters}
          entries={filteredData}
          subtypes={subtypes}
          submitATipLink={submitATipLink}
          perPage={perPage}
        />
      </Row>
    </>
  );
});
