import { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { HeaderBorder } from '../PossibilityBox/PossibilityBox';
import { Link } from 'react-router-dom';

interface PageProps {
  title: string;
  content: string;
  images: Array<{ small: string; large: string }>;
}

interface ParentDivProp {
  bgColor: string;
}

interface HeaderProp {
  titleColor: string;
}

interface CardProp {
  cardColor: string;
}

interface CardTitleProp {
  cardTitleColor: string;
}

interface ButtonProps {
  buttonBgColor: string;
  buttonTextColor: string;
}

export const MonthlyFeature = ({ manifestData }: any) => {
  const [cards, setCards] = useState<any>([]);
  const pages = manifestData?.['views']['monthlyFeature']['pages'];
  const config = manifestData?.['views']['monthlyFeature']['config'];

  useEffect(() => {
    const fetchCard1 = async () => {
      try {
        const response1 = await fetch(
          manifestData?.['features'][pages[0]]['url']
        );
        const responseData1 = await response1.json();
        const response2 = await fetch(
          manifestData?.['features'][pages[1]]['url']
        );
        const responseData2 = await response2.json();

        const data = await Promise.all([responseData1, responseData2]);

        setCards([...data]);
      } catch (e) {
        console.error(e);
      }
    };

    fetchCard1();
  }, [manifestData, pages]);

  if (!config) return <p>Loading...</p>;

  return (
    <ParentDiv bgColor={config?.bgColor}>
      <Header titleColor={config?.titleColor}>
        {manifestData?.['views']['monthlyFeature']['title']}
      </Header>
      <Grid>
        {cards?.map(
          ({ data: { title, content, images }, _id: { $id } }: any) => (
            <Card cardColor={config?.cardColor} key={$id}>
              <ImageDiv>
                <img
                  loading="lazy"
                  src={
                    images.length > 0
                      ? images[0]?.large
                      : manifestData?.['stylesheet']['images']['sheriffPatch'][
                          'url'
                        ]
                  }
                  alt={title}
                />
              </ImageDiv>
              <CardTitle cardTitleColor={config?.cardTitleColor}>
                {title}
              </CardTitle>
              <CardContent dangerouslySetInnerHTML={{ __html: content }} />
            </Card>
          )
        )}
      </Grid>
      <NominateButton
        buttonBgColor={config?.buttonBgColor}
        buttonTextColor={config?.buttonTextColor}
        to={config?.buttonLink}
      >
        {config?.buttonText}
      </NominateButton>
    </ParentDiv>
  );
};

export const ParentDiv = styled.div<ParentDivProp>`
  background: ${({ bgColor }) => bgColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 1.5rem;
`;

export const Header = styled.h1<HeaderProp>`
  color: ${({ titleColor }) => titleColor};
  font-family: Fjalla One, sans-serif;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  font-size: 2.25rem;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const Grid = styled.div`
  display: grid;
  align-content: center;
  justify-items: stretch;
  gap: 40px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  width: 100%;
  max-width: 900px;
`;

export const Card = styled.article<CardProp>`
  background: ${({ cardColor }) => cardColor};
  text-align: center;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ImageDiv = styled.div`
  width: 200px;
  height: 200px;
  margin: 0 auto;

  & > img {
    width: 100%;
    height: 100%;
  }
`;

export const CardTitle = styled.h1<CardTitleProp>`
  color: ${({ cardTitleColor }) => cardTitleColor};
  font-family: Fjalla One, sans-serif;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 1.75rem;
  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

export const CardContent = styled.p`
  font-family: Roboto, sans-serif;
  font-weight: lighter;
`;

export const NominateButton = styled(Link)<ButtonProps>`
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1rem;
  height: 50px;
  min-width: 175px;
  background: ${({ buttonBgColor }) => buttonBgColor};
  color: ${({ buttonTextColor }) => buttonTextColor};
  border: 1px solid ${({ buttonTextColor }) => buttonTextColor};
  font-family: Fjalla One, sans-serif;
  padding: 12px 18px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;

  &:hover,
  &focus {
    color: ${({ buttonTextColor }) => buttonTextColor};
  }
`;
