import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { Col, Row } from 'reactstrap';
import Swiper from 'react-id-swiper';
import Paper from '@material-ui/core/Paper/Paper';
import Lightbox from 'react-images';
import './OCVPage.css';

interface Props {
  classes: any;
  link: string;
  subtypes: any;
  viewData: any;
  anchorID?: any;
}

export default function OCVPage(props: Props) {
  const [pageData, setPageData] = useState<any>(null);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const openLightbox = (
    event: { preventDefault: () => void },
    index: number
  ) => {
    event.preventDefault();
    setCurrentImage(index);
    setLightboxIsOpen(true);
  };
  const closeLightbox = () => {
    setLightboxIsOpen(false);
  };
  const gotoPrevious = () => {
    setCurrentImage(currentImage - 1);
  };
  const gotoNext = () => {
    setCurrentImage(currentImage + 1);
  };

  useEffect(() => {
    try {
      fetch(props.link)
        .then((response) => response.json())
        .then((data) => {
          setPageData(data['data']);
        });
    } catch (e) {
      console.log(e);
    }
  }, [props.link]);

  let images: any[] = [];
  if (pageData !== null) {
    for (let i = 0; i < pageData.images.length; i++) {
      images.push({ src: pageData.images[i].large });
    }
  }
  if (pageData === null) {
    return (
      <div className="OCVPageExternalDiv">
        <ReactLoading
          className="loading-centered"
          type={'bars'}
          color={'#FFF'}
          height={'10%'}
          width={'10%'}
        />
      </div>
    );
  } else {
    const params = {
      parallax: true,
      slidesPerView: 1,
      speed: 3000,
      loop: pageData['images'].length !== 1,
      autoplay: {
        disableOnInteraction: true,
      },
    };
    const { subtypes, viewData, anchorID } = props;

    const config = viewData;
    let backgroundImageConfig = undefined;

    if (config?.rgba1 && config?.rgba2 && config?.backgroundImageURL)
      backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2}), url(${config.backgroundImageURL})`;
    else if (config?.rgba1 && config?.rgba2)
      backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2})`;
    else if (config?.backgroundImageURL)
      backgroundImageConfig = `url(${config.backgroundImageURL})`;

    return (
      <div
        id={anchorID}
        className={`bg-cover ${config?.additionalTailwindStyles}`}
        style={{
          backgroundColor: '#eee',
          backgroundImage: backgroundImageConfig,
          flexGrow: 2,
        }}
      >
        <Row
          className={
            subtypes.includes('imageAtTop')
              ? 'flex flex-col justify-center items-center'
              : 'flex flex-col-reverse justify-center items-center'
          }
        >
          {pageData.images.length !== 0 && !subtypes.includes('hideImage') ? (
            <Col className="OCVPageColOne w-full" xs={10} sm={10} md={10}>
              <Swiper {...params}>
                {pageData.images.map(
                  (image: { large: string; small: string }, index: number) => (
                    <div key={index}>
                      <img
                        onClick={(e) => openLightbox(e, index)}
                        alt={
                          'Page image ' +
                          (index + 1) +
                          ' of ' +
                          pageData.images.length +
                          ' (no alt text available)'
                        }
                        className="OCVPageImage object-contain max-h-72"
                        src={image.large}
                      />
                    </div>
                  )
                )}
              </Swiper>
              <Lightbox
                onClickNext={gotoNext}
                onClickPrev={gotoPrevious}
                backdropClosesModal
                currentImage={currentImage}
                onClose={closeLightbox}
                isOpen={lightboxIsOpen}
                images={images}
              />
            </Col>
          ) : null}
          <Col className="OCVPageColTwo w-full" xs={12} sm={10}>
            <Paper className="OCVPagePaper">
              <div
                className="OCVPageDiv"
                dangerouslySetInnerHTML={{
                  __html: pageData.content
                    .replaceAll('<h6>', '<h6 class="text-xs font-bold">')
                    .replaceAll('<h5>', '<h5 class="text-sm font-bold">')
                    .replaceAll('<h4>', '<h4 class="text-base font-bold">')
                    .replaceAll('<h3>', '<h3 class="text-xl font-bold">')
                    .replaceAll('<h2>', '<h2 class="text-2xl font-bold">')
                    .replaceAll('<h1>', '<h1 class="text-4xl font-bold">')
                    .replaceAll('<ul>', '<ul class="list-disc m-2 p-2">')
                    .replaceAll('<ol>', '<ol class="list-decimal m-2 p-2">')
                    .replaceAll('<a', '<a class="underline text-blue-800" '),
                }}
              />
            </Paper>
          </Col>
        </Row>
      </div>
    );
  }
}
